@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body, html, #root {
    @apply w-full h-full
  }
}

@layer components {
  .diagnostic-options {
    @apply block w-full text-left py-2 px-4 hover:bg-gray-100;
  }
  .diagnostic-overview {
    @apply text-sm sm:text-xl list-disc lg:px-4;
  }
  .btn-options {
    @apply text-black bg-zinc-300 font-medium text-sm justify-between px-4 py-2.5 w-full lg:w-60 h-9 text-center inline-flex items-center;
  }
  .diagnostics-results {
    @apply w-4 h-4 mr-2 text-green-400 bg-gray-100 rounded border-gray-300 focus:ring-transparent;
  }
  .dropdown {
    @apply py-1 text-sm text-gray-700 dark:text-gray-200;
  }
  .dropdown-container {
    @apply z-10 w-full lg:w-60 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute right-0;
  }
  .options-name {
    @apply text-lg flex items-center mt-5;
  }

  .options-container {
    @apply relative flex flex-col text-left;
  }

  .radio-container {
    @apply flex sm:w-5/12 w-11/12 items-center sm:mt-0 mt-5  justify-evenly;
  }

  .radio-white {
    @apply w-5 h-5 text-mainBlue focus:ring-0 focus:ring-offset-0  bg-white border-black;
  }

  .radio-white-container {
    @apply flex w-full  sm:h-full h-12 items-center bg-white mt-2 justify-evenly;
  }

  .radio-gray-container {
    @apply flex w-full  sm:h-full h-12 items-center bg-neutral-300 mt-2 justify-evenly;
  }

  .radio-gray {
    @apply w-5 h-5 text-mainBlue focus:ring-0 focus:ring-offset-0  bg-neutral-300 border-black;
  }

  .rate-options {
    @apply ml-2 text-base font-medium text-gray-900 dark:text-gray-300;
  }

  .competence-levels {
    @apply border-l text-sm w-25 justify-center h-6 flex items-center p-1 border-gray-400;
  }

  .competence-levels-table {
    @apply border-l flex items-center p-1 border-gray-400;
  }

  .arrow-icon {
    @apply inline-flex items-center text-mainBlue rounded-lg;
  }

  .icon-size {
    @apply w-6 h-6;
  }

  .title {
    @apply text-mainBlue font-semibold;
  }

  .mainTitle {
    @apply text-mainBlue font-semibold text-2xl mt-5 md:text-5xl mb-3 text-center;
  }

  .diagnostic-choice {
    @apply text-white bg-mainBlue text-base w-56 h-20 sm:text-xl sm:h-28 sm:w-96 mt-16;
  }

  .recomendations-container {
    @apply flex flex-col w-full border border-gray-400;
  }
}
.tick-cross-icon .competence-levels:first-of-type {
  border-left-width: 0;
}
.link {
  text-decoration: underline;
}
.link:hover {
  text-decoration: none;
}
